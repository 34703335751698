import React from 'react';
import { Link } from 'react-router-dom';
const logo = 'https://s3.amazonaws.com/assets.loudness/assets/commons/logo.png';

export default function Footer() {
  const footerLinks = [
    {
      liClass: 'mt-[10px]',
      route: 'https://loudness.com.mx/legals/privacy_policy/',
      title: 'Aviso de privacidad',
    },
    {
      liClass: 'mt-[10px]',
      route: 'https://loudness.com.mx/legals/terms_conditions/',
      title: 'Términos y condiciones de uso',
    },
  ];

  return (
    <div>
      <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
        <div className="container relative">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <div className="py-[60px] px-0">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                  <div className="lg:col-span-4 md:col-span-12">
                    <Link to="/#" className="text-[22px] focus:outline-none">
                      <img style={{ width: 90 }} src={logo} alt="" />
                    </Link>
                    <p className="mt-6 text-gray-300">música sin limites</p>
                  </div>

                  <div className="lg:col-span-4 md:col-span-4">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">Otros</h5>
                    <ul className="list-none footer-list mt-6">
                      {footerLinks.map((data, index) => (
                        <li key={index} className={data.liClass}>
                          <Link
                            target="_blank"
                            to={data.route}
                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i className="uil uil-angle-right-b"></i> {data.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-[30px] px-0 border-t border-slate-800">
          <div className="container relative text-center">
            <div className="grid md:grid-cols-2 items-center">
              <div className="md:text-start text-center">
                <p className="mb-0">© {new Date().getFullYear()} LOUDNESS.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
