import React from 'react';
import { Link } from 'react-router-dom';

import app from '@/assets/images/app/app.png';
import playstore from '@/assets/images/app/playstore.png';
import Navbar from '@/component/Navbar/navbar';
import Footer from '@/component/Footer/footer';

export default function IndexApps() {
  return (
    <>
      <Navbar navClass="nav-light" />

      <section className="relative table w-full py-36 md:py-56 md:pb-0 overflow-hidden bg-[url('../../assets/images/app/bg_landing.jpg')] bg-top bg-no-repeat bg-fixed bg-cover">
        <div className="absolute inset-0 bg-midnight-600 opacity-80"></div>
        <div className="container relative z-2">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-8 md:col-span-7 md:mb-16">
              <div className="md:me-6">
                <h4 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5">
                  LOUDNESS Música <br /> sin fronteras
                </h4>
                <p className="text-white/70 text-lg max-w-xl">
                  Escuchar y descubrir artistas independientes nunca fue tan fácil. Si eres artista
                  subir tu música a LOUDNESS esta a unos clicks de distancia.
                </p>

                <div className="mt-6">
                  <Link target="_blank" to="https://apps.apple.com/us/app/loudness/id1516558886">
                    <img src={app} className="inline-block m-1" alt="" />
                  </Link>
                  <Link
                    target="_blank"
                    to="https://play.google.com/store/apps/details?id=com.loudness"
                  >
                    <img src={playstore} className="inline-block m-1" alt="" />
                  </Link>
                </div>
              </div>
            </div>

            <div className="lg:col-span-4 md:col-span-5 md:mt-0">
              <div className="relative after:content-[''] after:absolute after:h-40 after:w-40 after:bg-loudness-600 after:bottom-0 after:end-0  after:-z-0 after:rounded-3xl after:animate-[spin_10s_linear_infinite]">
                <img
                  src="https://upcdn.io/FW25by8/image/assets.loudness/images/mockups/home_2.png?h=700"
                  className="relative z-1"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16 overflow-hidden">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl text-loudness-600 md:leading-normal text-2xl leading-normal font-semibold">
              LOUDNESS
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Somos LOUDNESS, una plataforma donde puedes encontrar música de artistas
              independientes, y si eres un artista o banda podrás subir tu contenido con tan solo
              unos cuantos clicks.
            </p>

            <div className="mt-4">
              <Link
                to="https://my.loudness.com.mx"
                target="_blank"
                className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-loudness-600 hover:text-loudness-600 after:bg-loudness-600 duration-500"
              >
                Sube tu música aquí <i className="uil uil-angle-right-b align-middle"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
            <div className="relative">
              <img
                src="https://upcdn.io/FW25by8/image/assets.loudness/images/mockups/player.png?h=600"
                className="mx-auto"
                alt=""
              />
              <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-loudness-600/5 bottom-2/4 translate-y-2/4 start-0 rotate-45 -z-1 shadow-md shadow-indigo-600/10 rounded-[100px]"></div>
            </div>

            <div className="lg:ms-8">
              <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-medium">
                Escucha gratis
              </h4>
              <p className="text-slate-400">
                Busca, encuentra y comparte la música que mas te guste, sabemos lo molestos que son
                los anuncios interrumpiendo tus canciones favoritas. Con LOUDNESS solo disfruta de
                escuchar tu contenido favorito.
              </p>
              <ul className="list-none text-slate-400 mt-4">
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-loudness-600 text-xl me-2"></i> Crea tus
                  playlists favoritas
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-loudness-600 text-xl me-2"></i> Marca tus
                  favoritos
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-loudness-600 text-xl me-2"></i> Comenta
                  tus opiniones
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
            <div className="relative order-1 md:order-2">
              <img
                src="https://upcdn.io/FW25by8/image/assets.loudness/images/mockups/timing.png?h=600"
                className="mx-auto"
                alt=""
              />
              <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-loudness-600/5 bottom-2/4 translate-y-2/4 end-0 rotate-45 -z-1 shadow-md shadow-indigo-600/10 rounded-[100px]"></div>
            </div>

            <div className="lg:me-8 order-2 md:order-1">
              <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-medium">
                Timing
              </h4>
              <p className="text-slate-400">
                Con LOUDNESS Timing podrás escuchar todo el contenido de LOUDNESS del genero músical
                que mas te guste, solo selecciona y dejate llevar.
              </p>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
            <div className="relative">
              <img
                src="https://upcdn.io/FW25by8/image/assets.loudness/images/mockups/artist.png?h=600"
                className="mx-auto"
                alt=""
              />
              <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-loudness-600/5 bottom-2/4 translate-y-2/4 start-0 rotate-45 -z-1 shadow-md shadow-indigo-600/10 rounded-[100px]"></div>
            </div>

            <div className="lg:ms-8">
              <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-medium">
                Artistas o Bandas
              </h4>
              <p className="text-slate-400">
                Crea tu perfil de artista o banda en tu portal My LOUDNESS, y comienza a subir tu
                contenido, conecta con nuevos fans y date a conocer
              </p>
              <ul className="list-none text-slate-400 mt-4">
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-loudness-600 text-xl me-2"></i> Sube
                  gratis tus sencillos, albums y podcasts
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-loudness-600 text-xl me-2"></i>
                  Conecta con nuevos fans
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-loudness-600 text-xl me-2"></i> Crea tu
                  perfil LOUDNESS
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              My LOUDNESS
            </h3>
            <p className="text-slate-400 max-w-xl mx-auto">
              Si eres artista o tienes una banda, te proporcionamos las herramientas necesarias para
              que puedas subir y gestionar todo tu contenido desde tu portal My LOUDNESS
            </p>

            <div className="">
              <img
                style={{ marginTop: 20 }}
                src="https://upcdn.io/FW25by8/image/assets.loudness/images/mockups/hub_media.png?w=1000"
                className="mx-auto md:max-w-[1000px]"
                alt=""
              />
            </div>

            <div className="mt-4">
              <Link
                to="https://my.loudness.com.mx"
                target="_blank"
                className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-loudness-600 hover:text-loudness-600 after:bg-loudness-600 duration-500"
              >
                Sube tu música aquí <i className="uil uil-angle-right-b align-middle"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-dark-footer">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="#0e0c21"></path>
          </svg>
        </div>
      </div>

      <Footer />
    </>
  );
}
