import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import IndexApps from '@/pages/index/indexApps';

import 'tiny-slider/dist/tiny-slider.css';
import 'react-18-image-lightbox/style.css';
import 'react-modal-video/css/modal-video.css';
import '@/assets/libs/@iconscout/unicons/css/line.css';
import '@/assets/libs/@mdi/font/css/materialdesignicons.min.css';
import '@/assets/css/tailwind.css';

import { ROUTES } from '@/constants/routes';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.HOME} element={<IndexApps />} />
      </Routes>
    </BrowserRouter>
  );
}
